import React from "react";
import QuizComponent from "../../components/Quiz";

const QuizPage = () => {
  return (
    <div>
      <QuizComponent />
    </div>
  );
};

export default QuizPage;