import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Grid2,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";

const ConceptCard = ({ url, concept_id, data }) => {
  const [conceptData, setConceptData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [expanded, setExpanded] = useState("summary"); // Default to Summary open
  const [isFavorited, setIsFavorited] = useState(false);

  console.log(data)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleFavorite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const item_id = conceptData.id;
      const item_type = "Concept";
      let response;
      if (isFavorited) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.ok) {
        setIsFavorited(!isFavorited);
      } else {
        const data = await response.json();
        console.error("Error updating favorite:", data.detail);
      }
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  useEffect(() => {
    const fetchArticleData = async () => {
      if (!data) {
        setLoading(true);
        try {
          const token = await getAccessTokenSilently();

          if (!url && !concept_id) {
            console.error("No URL or ID provided");
            setLoading(false);
            return;
          }

          let queryParams = "";
          if (url) {
            queryParams = `url=${encodeURIComponent(url)}`;
          } else if (concept_id) {
            queryParams = `id=${encodeURIComponent(concept_id)}`;
          }

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/concept?${queryParams}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            setConceptData(data);
            setIsFavorited(data.is_favorited);
          } else {
            console.error("Error fetching article:", data.detail);
          }
        } catch (error) {
          console.error("Error fetching article:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setConceptData(data)
        setIsFavorited(data.is_favorited)
        setLoading(false);
      }
    };

    fetchArticleData();
  }, [getAccessTokenSilently, url, concept_id, data]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!conceptData) {
    return <Typography variant="h6">Article not found</Typography>;
  }

  return (
    <Card sx={{ minWidth: 275 }} style={{ height: "100%" }}>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 size={12} style={{ marginLeft: "6px" }}>
            <Typography variant="h6">{conceptData.title}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }} style={{ marginLeft: "6px" }}>
            <Typography variant="subtitle1">Description</Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }} style={{ marginLeft: "6px" }}>
            <Typography variant="body1">{conceptData.description}</Typography>
          </Grid2>
        </Grid2>
      </CardContent>
      <CardActions style={{ marginBottom: "12px", marginLeft: "6px" }}>
        <Button
          variant="contained"
          color={isFavorited ? "secondary" : "primary"}
          onClick={handleToggleFavorite}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          {isFavorited ? <BookmarkIcon /> : <BookmarkBorderIcon />}{" "}
        </Button>
        <Button
          to={`/article?url=${encodeURIComponent(conceptData.source_url)}`}
          variant="contained"
          color={"primary"}
          component={RouterLink}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          View Article
        </Button>
      </CardActions>
    </Card>
  );
};

export default ConceptCard;
