import React, { useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // ag-Grid React component // Theme CSS
import { Card, CardContent, Typography } from "@mui/material"; // MUI components
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-quartz.css";

const IndexGrid = ({ indexes }) => {
  // Convert indexes prop to rows
  const rows = useMemo(() => {
    if (indexes) {
      return Object.keys(indexes).map((key) => ({
        indexName: key,
        ...indexes[key],
      }));
    }
  }, [indexes]);

  // Define columns for ag-Grid
  const [columnDefs] = useState([
    {
      field: "indexName",
      headerName: "Index Name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      field: "total_objects",
      headerName: "Total Objects",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      field: "updates_last_24_hours",
      headerName: "Updates (Last 24h)",
      sortable: true,
      filter: true,
      flex: 1,
    },
  ]);

  return (
    <Card style={{ width: "90%", margin: "auto", backgroundColor: "#474e5e" }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          style={{ color: "#FFF" }}
        >
          Indexes Overview
        </Typography>
        <div
          className="ag-theme-quartz-dark"
          style={{ height: 400, width: "100%" }}
        >
          <AgGridReact
            rowData={rows} // The data for the grid
            columnDefs={columnDefs} // The column definitions
            pagination={true}
            paginationPageSize={5} // Adjust page size as needed
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default IndexGrid;
