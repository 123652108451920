import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box, Alert, Grid2, Chip } from "@mui/material";
import IndexGrid from "./indexGrid";

const HealthChecks = () => {
  const [weaviateHealth, setWeaviateHealth] = useState(null);
  const [indexes, setIndexes] = useState(null); // Stores the backend message
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [error, setError] = useState(null);
  const [backendHealth, setBackendHealth] = useState(null);

  useEffect(() => {
    // Function to call the API endpoint
    const checkBackendHealth = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/public/`); // Fetch API call
        if (!response.ok) {
          throw new Error("Failed to fetch backend health");
        }
        const data = await response.json();
        setBackendHealth(data.message);
      } catch (err) {
        //@ts-ignore
        setError("Failed to fetch backend health");
      } finally {
        setLoading(false);
      }
    };

    // Function to call the OpenSearch health endpoint
    const checkWeviateHealth = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/public/weaviate-health`
        ); // OpenSearch API call
        if (!response.ok) {
          throw new Error("Failed to fetch OpenSearch health");
        }
        const data = await response.json();
        setWeaviateHealth(`Cluster Status: ${data.health}`); // Set the cluster health status
      } catch (err) {
        setError("Failed to fetch OpenSearch health");
      } finally {
        setLoading(false);
      }
    };


    const checkWeviateInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/public/weaviate-info`
        ); // OpenSearch API call
        if (!response.ok) {
          throw new Error("Failed to fetch OpenSearch health");
        }
        const data = await response.json(); // Set the cluster health status
        setIndexes(data.indexes);
      } catch (err) {
        setError("Failed to fetch OpenSearch health");
      } finally {
        setLoading(false);
      }
    };

    checkBackendHealth();
    checkWeviateHealth();
    checkWeviateInfo();
  }, []);

  return (
    <>
      <Grid2 container spacing={3}>
        <Grid2 xs={12} sm={6} style={{flexGrow: 1}}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={4}
          >
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h4" component="div" gutterBottom textAlign="center" style={{margin: "auto"}}>
                  Backend Health Check
                </Typography>
                {backendHealth && <Chip color="success" label="Backend Status: Healthy" />}
              </Box>
            )}
          </Box>
        </Grid2>
        <Grid2 xs={12} sm={6} style={{flexGrow: 1}} >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={4}
          >
            <Typography variant="h4" component="div" gutterBottom textAlign="center" style={{margin: "auto"}} >
                    Weaviate Health Check
                  </Typography>
            {loading || !weaviateHealth ? (
              <CircularProgress />
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  
                  {weaviateHealth && <Chip color="success" label={weaviateHealth} />}
                  {/* <Typography variant="body1">{weaviateHealth}</Typography> */}
                </Box>
              </>
            )}
          </Box>
        </Grid2>
          <Grid2 size={12} style={{flexGrow: 1}} >
           {loading ? (
              <CircularProgress />
            ) : (<IndexGrid indexes={indexes} />)}
          </Grid2>
      </Grid2>
    </>
  );
};

export default HealthChecks;
