import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";

const AuthButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return isAuthenticated ? (
    <Button
      variant="contained"
      color="secondary"
      onClick={() =>
        logout({
          returnTo: "/",
        })
      }
    >
       Log Out
    </Button>
  ) : (
    <Button variant="contained" color="primary" onClick={() => loginWithRedirect({ screen_hint: 'login' })}>
      Log In
    </Button>
  );
};

export default AuthButton;