import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const QuizComponent = () => {
  const [similarityQuery, setSimilarityQuery] = useState("");
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchQuestions = async (useSimilarity) => {
    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/generate-questions?num_questions=10`;
      if (useSimilarity && similarityQuery.trim() !== "") {
        url += `&similarity_query=${encodeURIComponent(
          similarityQuery.trim()
        )}`;
      }
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }
      const data = await response.json();
      setQuestions(data.questions);
      setUserAnswers({});
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Failed to fetch questions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerSelect = (questionIndex, selectedAnswer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: selectedAnswer,
    }));
  };

  const renderQuestions = () => {
    return questions.map((question, index) => (
      <Paper key={index} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h6">{`Question ${index + 1}: ${
          question.question
        }`}</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={userAnswers[index] || ""}
            onChange={(e) => handleAnswerSelect(index, e.target.value)}
          >
            {question.answers.map((answer) => (
              <FormControlLabel
                key={answer.letter}
                value={answer.letter}
                control={<Radio />}
                label={`${answer.letter}. ${answer.answer_text}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {userAnswers[index] && (
          <Typography
            variant="body1"
            style={{
              marginTop: "10px",
              color:
                userAnswers[index] === question.correct_answer
                  ? "green"
                  : "red",
            }}
          >
            {userAnswers[index] === question.correct_answer
              ? "Correct!"
              : `Incorrect. The correct answer is ${question.correct_answer}.`}
          </Typography>
        )}
        {userAnswers[index] && (
          <Typography variant="body2" style={{ marginTop: "10px" }}>
            Explanation: {question.explanation}
          </Typography>
        )}
      </Paper>
    ));
  };

  return (
    <div style={{ maxWidth: "95%", margin: "auto", marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Quiz
      </Typography>
      <TextField
        label="Similarity Query"
        variant="outlined"
        fullWidth
        value={similarityQuery}
        onChange={(e) => setSimilarityQuery(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => fetchQuestions(true)}
        style={{ marginRight: "10px" }}
        disabled={loading}
      >
        Generate Quiz
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => fetchQuestions(false)}
        disabled={loading}
      >
        Random Quiz
      </Button>

      {loading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      )}

      {error && (
        <Typography color="error" style={{ marginTop: "20px" }}>
          {error}
        </Typography>
      )}

      {!loading && questions.length > 0 && (
        <div style={{ marginTop: "20px" }}>{renderQuestions()}</div>
      )}
    </div>
  );
};

export default QuizComponent;