// index.js
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App"; // Importing App component
import "./global.css";
import CssBaseline from "@mui/material/CssBaseline";

// Create the light theme
const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2", // Adjust as needed
    },
    background: {
      default: "#ffffff", // Set light mode background color for the body
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Noto Sans, sans-serif",
  },
});

// Create the dark theme
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Adjust as needed
    },
    background: {
      default: "#121A21", // Set dark mode background color for the body
      paper: "#1d2338",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Noto Sans, sans-serif",
  },
});

const Root = () => {
  const [themeMode, setThemeMode] = useState("dark"); // Default to dark mode

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  };

  const theme = themeMode === "dark" ? darkTheme : lightTheme;

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App toggleTheme={toggleTheme} themeMode={themeMode} />
        </Router>
      </ThemeProvider>
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
