import { Grid2, Paper, Typography } from "@mui/material";
import ConceptsPagination from "../../components/Concepts/index";

const ConceptsPage = () => {
  return (
    <>
      <Typography variant="h3" style={{ padding: "20px" }}>
        Concepts
      </Typography>
      <ConceptsPagination />
    </>
  );
};

export default ConceptsPage;
