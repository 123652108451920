import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Chart from "./Chart";

const Visualization = () => {
  const [articleData, setArticleData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [conceptData, setConceptData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getVectors(className, setData) {
      try {
        const token = await getAccessTokenSilently();

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/vectors?class_name=${className}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch vectors for ${className}`);
        }
        const data = await response.json();
        const vectors = data.vectors;
        const labels = data.labels;
        const ids = data.ids;
        const urls = data.urls;

        // Combine data into an array of objects
        const combinedData = vectors.map((vector, index) => ({
          x: vector[0],
          y: vector[1],
          label: labels[index],
          id: ids[index],
          url: urls[index],
        }));
        setData(combinedData);
      } catch (err) {
        console.log(err);
      }
    }
    getVectors("MedicalArticle", setArticleData);
    getVectors('MedicalTerm', setTermData);
    getVectors('MedicalConcept', setConceptData);
  }, [getAccessTokenSilently]);

  return (
    <div>
      <Chart data={articleData} name="Article" color="#8884d8" />
      <Chart data={termData} name="Term" color="#82ca9d" />
      <Chart data={conceptData} name="Concept" color="#ffc658" />
    </div>
  );
};

export default Visualization;
