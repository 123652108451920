import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Grid2,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from 'react-router-dom';

const TermCard = ({ url, term_id, data }) => {
  const [termData, setTermData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [expanded, setExpanded] = useState("summary"); // Default to Summary open
  const [isFavorited, setIsFavorited] = useState(false);

  console.log(data)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleFavorite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const item_id = termData.id;
      const item_type = "Term";
      let response;
      if (isFavorited) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.ok) {
        setIsFavorited(!isFavorited);
      } else {
        const data = await response.json();
        console.error("Error updating favorite:", data.detail);
      }
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  useEffect(() => {
    const fetchArticleData = async () => {
        if(!data){
            setLoading(true);
            try {
              const token = await getAccessTokenSilently();
      
              if (!url && !term_id) {
                console.error("No URL or ID provided");
                setLoading(false);
                return;
              }
      
              let queryParams = "";
              if (url) {
                queryParams = `url=${encodeURIComponent(url)}`;
              } else if (term_id) {
                queryParams = `id=${encodeURIComponent(term_id)}`;
              }
      
              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/term?${queryParams}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const data = await response.json();
              if (response.ok) {
                setTermData(data);
                setIsFavorited(data.is_favorited);
              } else {
                console.error("Error fetching article:", data.detail);
              }
            } catch (error) {
              console.error("Error fetching article:", error);
            } finally {
              setLoading(false);
            }
        } else {
            setTermData(data);
            setIsFavorited(data.is_favorited)
            setLoading(false);
        }
      
    };

    fetchArticleData();
  }, [getAccessTokenSilently, url, term_id, data]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!termData) {
    return <Typography variant="h6">Term not found</Typography>;
  }

  return (
    <Card sx={{ minWidth: 275 }} style={{height: "100%"}} >
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 size={12} style={{ marginLeft: "6px" }}>
            <Typography variant="h6">{termData.term}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 12}} style={{ marginLeft: "6px" }}>
            <Typography variant="subtitle1">Definition</Typography>
          </Grid2>
          <Grid2 size={{ xs: 12}} style={{ marginLeft: "6px" }}>
            <Typography variant="body1">
              {termData.definition}
            </Typography>
          </Grid2>
        </Grid2>
      </CardContent>
      <CardActions style={{ marginBottom: "12px", marginLeft: "6px" }}>
        <Button
          variant="contained"
          color={isFavorited ? "secondary" : "primary"}
          onClick={handleToggleFavorite}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          {isFavorited ? <BookmarkIcon /> : <BookmarkBorderIcon />}{" "}
        </Button>
        <Button
          to={`/article?url=${encodeURIComponent(termData.url)}`}
          variant="contained"
          color={"primary"}
          component={RouterLink}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          View Article
        </Button>
      </CardActions>
    </Card>
  );
};

export default TermCard;
