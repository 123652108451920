import React from "react";
import { Typography } from "@mui/material";
import SearchComponent from "../../components/Search/index";

const SearchPage = () => {
  return (
    <>
      <Typography variant="h3" style={{ padding: "20px" }}>
        Search
      </Typography>
      <SearchComponent />
    </>
  );
};

export default SearchPage;