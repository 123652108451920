import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Button,
  List,
  ListItem,
  Paper,
  Link,
  useTheme
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeSanitize from "rehype-sanitize";
import { useAuth0 } from "@auth0/auth0-react";

function ChatInterface() {
  const [messages, setMessages] = useState([]); // Chat history
  const [inputText, setInputText] = useState(""); // Current input
  const { getAccessTokenSilently } = useAuth0();
  
  // Access current theme using useTheme hook
  const theme = useTheme();

  const sendMessage = async (message) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ question: message }),
        }
      );
      const data = await response.json();
      const modelMessage = { sender: "model", text: data.answer };
      setMessages((prevMessages) => [...prevMessages, modelMessage]);
      // Update your chat history with data.answer and data.referenced_docs if needed
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Handle sending a message
  const handleSend = async () => {
    if (inputText.trim() === "") return;

    // Add user's message to chat history
    const userMessage = { sender: "user", text: inputText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Clear input field
    setInputText("");

    sendMessage(inputText);
  };

  // Handle refreshing the chat
  const handleRefresh = async () => {
    // Clear chat history
    setMessages([]);

    try {
      // Call backend refresh endpoint
      console.log("Refreshed");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        marginTop: "16px",
        maxWidth: "95%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* App Bar with Refresh Button */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Chat With The Articles
          </Typography>
          <IconButton color="inherit" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Chat History */}
      <Paper style={{ height: "60vh", overflowY: "auto", padding: "1em" }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              style={{
                justifyContent:
                  msg.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              <div
                style={{
                  textAlign: msg.sender === "user" ? "right" : "left",
                  backgroundColor:
                    msg.sender === "user"
                      ? theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : "#8dbded"
                      : theme.palette.mode === "dark"
                      ? theme.palette.background.paper
                      : "#F1F0F0",
                  borderRadius: "10px",
                  padding: "0.5em 1em",
                  maxWidth: "75%",
                  color:
                    msg.sender === "user"
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                }}
              >
                <Typography
                  component="div"
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "1rem",
                  }}
                >
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeSanitize]}
                    components={{
                      p: ({ node, ...props }) => (
                        <Typography variant="body1" style={{marginTop: "12px"}} {...props} />
                      ),
                      h1: ({ node, ...props }) => (
                        <Typography variant="h4" {...props} />
                      ),
                      h2: ({ node, ...props }) => (
                        <Typography variant="h5" {...props} />
                      ),
                      h3: ({ node, ...props }) => (
                        <Typography variant="h6" {...props} />
                      ),
                      ul: ({ node, ...props }) => (
                        <ul style={{ paddingLeft: "20px", marginTop: "12px" }} {...props} />
                      ),
                      ol: ({ node, ...props }) => (
                        <ol style={{ paddingLeft: "20px", marginTop: "12px" }} {...props} />
                      ),
                      code: ({
                        node,
                        inline,
                        className,
                        children,
                        ...props
                      }) => (
                        <Typography

                          component="span"
                          style={{
                            fontFamily: "monospace",
                            backgroundColor: theme.palette.background.paper,
                            padding: "0.2em",
                            color: theme.palette.text.primary,
                          }}
                          {...props}
                        >
                          {children}
                        </Typography>
                      ),
                      a: ({ href, children, ...props }) => (
                        <Link href={href} target="_blank" {...props}>
                          {children}
                        </Link>
                      ),
                    }}
                  >
                    {msg.text}
                  </ReactMarkdown>
                </Typography>
              </div>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Input Field and Send Button */}
      <div style={{ display: "flex", padding: "1em" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSend();
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          style={{ marginLeft: "1em" }}
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default ChatInterface;