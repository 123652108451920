// src/pages/Visualization/index.jsx

import { Typography } from "@mui/material";
import Visualization from "../../components/Visualization/index";

const VisualizationPage = () => {
  return (
    <>
      <Typography variant="h3" style={{ padding: "20px" }}>
        Embeddings Visualization
      </Typography>
      <Visualization />
    </>
  );
};

export default VisualizationPage;
