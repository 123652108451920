import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Grid2,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleCard from "../../components/EntityCards/ArticleCard";
import ConceptCard from "../../components/EntityCards/ConceptCard";
import TermCard from "../../components/EntityCards/TermCard";

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);
  const [bookmarkedConcepts, setBookmarkedConcepts] = useState([]);
  const [bookmarkedTerms, setBookmarkedTerms] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setFavorites(data);
          const articles = [];
          const concepts = [];
          const terms = [];
          data.forEach((item) => {
            if (item.item_type == "Article") {
              articles.push(item);
            }
            if (item.item_type == "Concept") {
              concepts.push(item);
            }
            if (item.item_type == "Term") {
              terms.push(item);
            }
          });
          console.log(articles);
          setBookmarkedArticles([...articles]);
          setBookmarkedConcepts([...concepts]);
          setBookmarkedTerms([...terms]);
        } else {
          console.error("Error fetching favorites:", data.detail);
        }
      } catch (error) {
        console.error("Error fetching favorites:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [getAccessTokenSilently]);

  const handleRemoveFavorite = async (item_type, item_id) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        setFavorites(
          favorites.filter(
            (fav) => !(fav.item_type === item_type && fav.item_id === item_id)
          )
        );
      } else {
        const data = await response.json();
        console.error("Error removing favorite:", data.detail);
      }
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (favorites.length === 0) {
    return <Typography variant="h6">You have no bookmarks.</Typography>;
  }

  return (
    <div style={{ maxWidth: "95%", margin: "auto" }}>
      <Typography
        variant="h4"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        Your Bookmarks
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Typography variant="h6">Bookmarked Articles:</Typography>
        </Grid2>
        {bookmarkedArticles.map((article) => (
          <Grid2 size={12}>
            <ArticleCard article_id={article.item_id} />
          </Grid2>
        ))}
        <Grid2 size={12}>
          <Typography variant="h6">Bookmarked Concepts:</Typography>
        </Grid2>
        {bookmarkedConcepts.map((concept) => (
          <Grid2 size={{ xs: 12, md: 6 }}>
            <ConceptCard concept_id={concept.item_id} />
          </Grid2>
        ))}
        <Grid2 size={12}>
          <Typography variant="h6">Bookmarked Terms:</Typography>
        </Grid2>
        {bookmarkedTerms.map((term) => (
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TermCard term_id={term.item_id} />
          </Grid2>
        ))}
      </Grid2>
    </div>
  );
};

export default FavoritesPage;
