// src/pages/LandingPage/index.jsx
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Grid2,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as MachineLearningOne } from '../../assets/icons/machine-learning-01-svgrepo-com.svg'; 
import QOTDComponent from "../../components/QOTD";

const LandingPage = () => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="300"
        height="300"
        style={{
          position: 'fixed',
          right: '120px', // Fixed to the right
          bottom: '220px', // Fixed to the bottom
          opacity: 0.5, // Add transparency
          fill: '#FFF', // Dynamically change color if needed
        }}
      >
        <MachineLearningOne style={{color: "#FFF"}} />
      </svg>   */}
      {/* Hero Section */}
      <Box
        sx={{
          py: 8,
        }}
      >
        <Container maxWidth="md" textAlign="center">
          <Typography variant="h3" gutterBottom>
            Transforming Medical Research with AI-Powered Insights
          </Typography>
          <Typography variant="h6" gutterBottom>
            Stay ahead in oncology research with MedMeta's advanced analytics.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/signup"
            sx={{ mt: 4 }}
          >
            Sign Up Now
          </Button>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
      <QOTDComponent />
        <Typography variant="h4" align="center" gutterBottom>
          Features
        </Typography>
        <Grid2 container spacing={3} >
          <Grid2 size={{ xs: 12, md: 6 }} padding={2}>
            <Typography variant="h5" align="justify">Semantic Search</Typography>
            <Typography align="justify">
              Find relevant research faster with AI-driven semantic search that
              understands context and meaning.
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }} padding={2}>
            <Typography variant="h5" align="justify">Vector Space Visualization</Typography>
            <Typography align="justify" >
              Visualize complex relationships between studies to uncover hidden
              patterns and trends.
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }} padding={2}>
            <Typography align="justify" variant="h5">Chat Interface</Typography>
            <Typography align="justify">
              Interact with our AI assistant to get instant answers and
              summaries from vast medical literature.
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }} padding={2}>
                <Typography variant="h5" align="justify" >Term & Concept Lists</Typography>
                <Typography align="justify" >
                Access curated lists of medical terms and concepts to deepen your
                understanding.
                </Typography>
          </Grid2>
        </Grid2>
      </Container>

      {/* Benefits Section */}
      <Box sx={{ py: 8 }}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" gutterBottom>
            Benefits for Medical Professionals
          </Typography>
          <Grid2 container spacing={4} justifyContent="center">
            {/* Benefit 1 */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h6" align="center">
                Save Valuable Time
              </Typography>
              <Typography align="center">
                Quick access to the most relevant research.
              </Typography>
            </Grid2>
            {/* Benefit 2 */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h6" align="center">
                Enhance Patient Care
              </Typography>
              <Typography align="center">
                Stay informed about the latest treatments and findings.
              </Typography>
            </Grid2>
            {/* Benefit 3 */}
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h6" align="center">
                Collaborate
              </Typography>
              <Typography align="center">
                Share insights with peers through our integrated platform.
              </Typography>
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          py: 6,
        }}
      >
        <Container maxWidth="sm" textAlign="center">
          <Typography variant="h4" gutterBottom>
            Ready to Revolutionize Your Research?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/signup"
          >
            Join MedMeta Today
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ backgroundColor: "grey.900", color: "grey.300", py: 4 }}>
        <Container maxWidth="lg">
          <Grid2 container spacing={4}>
            <Grid2 item xs={12} sm={6}>
              <Typography variant="h6">MedMeta</Typography>
              <Typography variant="body2">
                Transforming Medical Research with AI.
              </Typography>
            </Grid2>
            <Grid2 item xs={12} sm={6} textAlign="right">
              <Typography variant="body2">
                &copy; {new Date().getFullYear()} MedMeta. All rights reserved.
              </Typography>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </div>
  );
};

export default LandingPage;
