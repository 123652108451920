import { Grid2, Paper, Typography } from "@mui/material";
import HealthChecks from "../../components/HealthChecks/index";

const Home = () => {
  return (
    <>
      <HealthChecks />
    </>
  );
};

export default Home;
