import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid2,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from 'react-router-dom';
import ConceptCard from "../EntityCards/ConceptCard/index.js"

const ConceptsPagination = () => {
  const [concepts, setConcepts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fetchConcepts = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/concepts?page_size=${pageSize}&page=${page}&sort_by=alphabetical`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setConcepts(data.concepts);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error("Error fetching concepts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConcepts();
  }, [page, pageSize]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1); // Reset to page 1 when page size changes
  };

  return (
    <Grid2 container spacing={3} style={{ maxWidth: "95%", margin: "auto" }}>
      {concepts.map((concept) =>(<Grid2 size={{ xs: 12, md: 6 }}>
        <ConceptCard data={concept} />
      </Grid2>))}
      <Grid2 xs={4} sm={3}>
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 120,
            marginBottom: 2,
          }}
        >
          <InputLabel sx={{ color: "white" }}>Page Size</InputLabel>
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            label="Page Size"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 size={7}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{
            marginTop: 2, // White border
            "& .MuiPaginationItem-root": { color: "white" }, // White text for pagination items
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default ConceptsPagination;
