import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Paper,
  Typography,
  Grid2,
  CircularProgress,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ArticleCard from "../EntityCards/ArticleCard";
import ConceptCard from "../EntityCards/ConceptCard";
import TermCard from "../EntityCards/TermCard";

const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDatasets, setSelectedDatasets] = useState([
    "Article",
    "Term",
    "Concept",
  ]);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    // Clear previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set new timeout
    setTypingTimeout(
      setTimeout(() => {
        performSearch(value);
      }, 3000) // Wait 3 seconds after the last keyup
    );
  };

  const handleDatasetChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDatasets(typeof value === "string" ? value.split(",") : value);
  };

  const performSearch = async (query) => {
    if (!query) {
      setResults({});
      return;
    }

    setLoading(true);

    try {
      // Build query parameters
      const token = await getAccessTokenSilently();
      const params = new URLSearchParams();
      params.append("query_text", query);
      selectedDatasets.forEach((dataset) => params.append("datasets", dataset));

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/search?${params.toString()}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error performing search:", error);
      setResults({});
    } finally {
      setLoading(false);
    }
  };

  // Effect to perform search when datasets change
  useEffect(() => {
    if (searchQuery) {
      // Clear previous timeout
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set new timeout
      setTypingTimeout(
        setTimeout(() => {
          performSearch(searchQuery);
        }, 3000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatasets]);

  return (
    <div style={{ maxWidth: "95%", margin: "auto" }}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={8} style={{ flexGrow: 1 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="dataset-select-label">Datasets</InputLabel>
            <Select
              labelId="dataset-select-label"
              id="dataset-select"
              multiple
              value={selectedDatasets}
              onChange={handleDatasetChange}
              renderValue={(selected) => selected.join(", ")}
              label="Datasets"
            >
              {["Article", "Term", "Concept"].map((dataset) => (
                <MenuItem key={dataset} value={dataset}>
                  <Checkbox checked={selectedDatasets.indexOf(dataset) > -1} />
                  <ListItemText primary={dataset} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>

      {loading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      )}

      {!loading && Object.keys(results).length === 0 && searchQuery && (
        <Typography style={{ marginTop: "20px" }}>No results found.</Typography>
      )}

      {!loading &&
        Object.keys(results).map((className) => (
          <Paper
            key={className}
            style={{ padding: "20px", margin: "20px 0" }}
            elevation={3}
          >
            <Typography variant="h5" style={{ marginBottom: "10px" }}>
              {className}
            </Typography>
            <Grid2 container spacing={2}>
              {results[className].length > 0 ? (
                results[className].map((item, index) => (
                  < >
                    {/* Display item fields based on class */}
                    {className === "Article" && (
                      <Grid2 size={12} key={index} style={{ marginBottom: "15px" }}>
                        <ArticleCard article_id={item.id} />
                        <Typography variant="body2" color="textSecondary">
                          Distance: {item.score.toFixed(4)}
                        </Typography>
                      </Grid2>
                    )}
                    {className === "Term" && (
                      <Grid2 size={{ xs: 12, md: 6 }} key={index} style={{ marginBottom: "15px" }}>
                        <TermCard data={item} />

                        <Typography variant="body2" color="textSecondary">
                          Distance: {item.score.toFixed(4)}
                        </Typography>
                      </Grid2>
                    )}
                    {className === "Concept" && (
                      <Grid2 size={{ xs: 12, md: 6 }} key={index} style={{ marginBottom: "15px" }}>
                        <ConceptCard data={item} />

                        <Typography variant="body2" color="textSecondary">
                          Distance: {item.score.toFixed(4)}
                        </Typography>
                      </Grid2>
                    )}
                  </>
                ))
              ) : (
                <Typography>No results found for {className}</Typography>
              )}
            </Grid2>
          </Paper>
        ))}
    </div>
  );
};

export default SearchComponent;
