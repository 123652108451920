import { Paper, Typography } from "@mui/material";
import TermsPagination from "../../components/Terms/index";

const TermsPage = () => {
  return (
    <>
      <Typography variant="h3" style={{ padding: "20px" }}>
        Terms
      </Typography>
      <TermsPagination />
    </>
  );
};

export default TermsPage;
