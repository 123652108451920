// src/components/Visualization/Chart.jsx

import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Chart = ({ data, name, color }) => {
  const navigate = useNavigate();

  // Function to calculate dynamic point size based on the number of data points
  const calculatePointSize = (numPoints) => {
    if (numPoints <= 1000) {
      return 6;  // Default point size for 1000 or fewer points
    } else {
        return 1;
    }
    
    // Scale down the point size for more than 1000 points
    // Example: For every 1000 points beyond 1000, reduce the point size by 0.5
    // const scaleFactor = Math.max(2, 6 - (numPoints) / 1000 * 0.7); // Minimum size of 2
    // return scaleFactor;
  };

  const pointSize = calculatePointSize(data.length);

  const handleClick = (e) => {
    const dataPoint = e.payload;
    const url = dataPoint.url;
    if (url) {
      navigate(`/article?url=${encodeURIComponent(url)}`);
    }
  };

  return (
    <div>
      <Typography variant="h4" style={{ padding: "20px" }}>
        {name} Embeddings
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart>
          <XAxis dataKey="x" type="number" name="X" />
          <YAxis dataKey="y" type="number" name="Y" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div style={{ 
                    backgroundColor: '#fff', 
                    padding: '5px', 
                    border: '1px solid #ccc', 
                    color: '#282c34',
                    borderRadius: '4px'
                  }}>
                    <Typography variant="body1" style={{ color: '#282c34' }}>
                      {payload[0].payload.label}
                    </Typography>
                  </div>
                );
              }
              return null;
            }}
          />
          <Scatter
            name={name}
            data={data}
            fill={color}
            onClick={handleClick}
            // Use dynamic point size based on the number of data points
            size={pointSize}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;