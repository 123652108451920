import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material/styles";
import QuizPage from "./pages/Quiz/index.jsx";

import LandingPage from "./pages/LandingPage/index.jsx";

// Placeholder imports for components
import Home from "./pages/Home"; // Adjust the path as needed
import Concepts from "./pages/Concepts"; // Adjust the path as needed
import Terms from "./pages/Terms";
import Search from "./pages/Search";
import Visualization from "./pages/Visualization";
import Chat from "./pages/Chat"; // Adjust the path as needed
import Login from "./components/Login/index.jsx"; // Adjust the path as needed
import ArticlePage from "./pages/Article/index.jsx"; // Adjust the path as needed
import BookmarksPage from "./pages/Bookmarks/index.jsx";
import Flashcards from "./pages/Flashcards/index.jsx";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./components/ProtectedRoute/index.jsx";
import BugReportPage from "./pages/BugReport/index.jsx";

function App({ toggleTheme, themeMode }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const theme = useTheme();

  const { isAuthenticated, isLoading } = useAuth0();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText style={{ color: "white" }} primary="Home" />
        </ListItem>
        {isAuthenticated && (
          <>
            <ListItem button component={Link} to="/concepts">
              <ListItemText style={{ color: "white" }} primary="Concepts" />
            </ListItem>
            <ListItem button component={Link} to="/terms">
              <ListItemText style={{ color: "white" }} primary="Terms" />
            </ListItem>
            <ListItem button component={Link} to="/search">
              <ListItemText style={{ color: "white" }} primary="Search" />
            </ListItem>
            <ListItem button component={Link} to="/visualization">
              <ListItemText
                style={{ color: "white" }}
                primary="Visualization"
              />
            </ListItem>
            <ListItem button component={Link} to="/chat">
              <ListItemText style={{ color: "white" }} primary="Chat" />
            </ListItem>
            <ListItem button component={Link} to="/bookmarks">
              <ListItemText style={{ color: "white" }} primary="Bookmarks" />
            </ListItem>
            <ListItem button component={Link} to="/flashcards">
              <ListItemText style={{ color: "white" }} primary="Flashcards" />
            </ListItem>
            <ListItem button component={Link} to="/quiz">
              <ListItemText style={{ color: "white" }} primary="Quiz" />
            </ListItem>
          </>
        )}
        {!isLoading && (
          <div style={{ marginLeft: "12px" }}>
            <Login />
          </div>
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "background.default", // Use the theme's background color
        color: "text.primary", // Use the theme's text color
        minHeight: "100vh", // Full page height
      }}
    >
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.default, // Same color as background
          color: "text.primary", // Text color based on theme
          backgroundImage: "none",
          borderBottom: `1px solid ${
            theme.palette.mode === "dark" ? "#ffffff" : "#d3d3d3"
          }`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MedMeta
          </Typography>

          {isSmallScreen ? (
            <>
              <IconButton
                color="inherit"
                onClick={toggleTheme}
                style={{ marginRight: "12px" }}
              >
                {themeMode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
              <IconButton
                color="inherit"
                edge="start"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawerContent}
              </Drawer>
            </>
          ) : (
            <Box>
              <Button color="inherit" component={Link} to="/">
                Home
              </Button>
              {isAuthenticated && (
                <>
                  <Button color="inherit" component={Link} to="/concepts">
                    Concepts
                  </Button>
                  <Button color="inherit" component={Link} to="/terms">
                    Terms
                  </Button>
                  <Button color="inherit" component={Link} to="/search">
                    Search
                  </Button>
                  <Button color="inherit" component={Link} to="/visualization">
                    Visualization
                  </Button>
                  <Button color="inherit" component={Link} to="/chat">
                    Chat
                  </Button>
                  <Button color="inherit" component={Link} to="/bookmarks">
                    Bookmarks
                  </Button>
                  <Button color="inherit" component={Link} to="/flashcards">
                    Flashcards
                  </Button>
                  <Button color="inherit" component={Link} to="/quiz">
                    Quiz
                  </Button>
                  <IconButton
                    color="inherit"
                    onClick={toggleTheme}
                    style={{ marginRight: "12px" }}
                  >
                    {themeMode === "dark" ? (
                      <Brightness7Icon />
                    ) : (
                      <Brightness4Icon />
                    )}
                  </IconButton>
                </>
              )}
              {!isLoading && <Login />}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Main content area where different components are rendered */}
      <main style={{ maxWidth: "1028px", margin: "auto" }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/metrics" element={<Home />} />
          <Route
            path="/concepts"
            element={
              <ProtectedRoute>
                <Concepts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoute>
                <Terms />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute>
                <Search />
              </ProtectedRoute>
            }
          />
          <Route
            path="/visualization"
            element={
              <ProtectedRoute>
                <Visualization />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/article"
            element={
              <ProtectedRoute>
                <ArticlePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookmarks"
            element={
              <ProtectedRoute>
                <BookmarksPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/flashcards"
            element={
              <ProtectedRoute>
                <Flashcards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bug-report"
            element={
              <ProtectedRoute>
                <BugReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/quiz"
            element={
              <ProtectedRoute>
                <QuizPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>

      {/* Placeholder for a footer, if you want */}
      <footer></footer>
    </Box>
  );
}

export default App;
