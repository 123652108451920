import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useAuth0 } from "@auth0/auth0-react";
import TermCard from "../../components/EntityCards/TermCard";
import ConceptCard from "../../components/EntityCards/ConceptCard";

const ArticlePage = () => {
  const [articleData, setArticleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [expanded, setExpanded] = useState("summary"); // Default to Summary open
  const [isFavorited, setIsFavorited] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleFavorite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const item_id = articleData.article.id;
      const item_type = "Article";
      let response;
      if (isFavorited) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.ok) {
        setIsFavorited(!isFavorited);
      } else {
        const data = await response.json();
        console.error("Error updating favorite:", data.detail);
      }
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  useEffect(() => {
    const fetchArticleData = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();

        const searchParams = new URLSearchParams(location.search);
        const urlParam = searchParams.get("url");
        const idParam = searchParams.get("id");

        if (!urlParam && !idParam) {
          console.error("No URL or ID provided");
          setLoading(false);
          return;
        }

        let queryParams = "";
        if (urlParam) {
          queryParams = `url=${encodeURIComponent(urlParam)}`;
        } else if (idParam) {
          queryParams = `id=${encodeURIComponent(idParam)}`;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/article?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setArticleData(data);
        } else {
          console.error("Error fetching article:", data.detail);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleData();
  }, [getAccessTokenSilently, location.search]);

  // Moved `useEffect` for checking if the article is favorited outside the conditional return
  useEffect(() => {
    const checkIfFavorited = async () => {
      if (articleData) {
        try {
          const token = await getAccessTokenSilently();
          const item_id = articleData.article.id;
          const item_type = "Article";
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            setIsFavorited(data.is_favorited);
          }
        } catch (error) {
          console.error("Error checking favorite:", error);
        }
      }
    };

    checkIfFavorited();
  }, [articleData, getAccessTokenSilently]); // Include articleData as a dependency

  if (loading) {
    return <CircularProgress />;
  }

  if (!articleData) {
    return <Typography variant="h6">Article not found</Typography>;
  }

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  return (
    <Paper style={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h4">{articleData.article.title}</Typography>
      <Typography variant="subtitle1">
        Published on: {formatDate(articleData.article.publication_date)}
      </Typography>
      <Typography variant="subtitle1">
        Ingested on: {formatDate(articleData.article.timestamp)}
      </Typography>
      <Button
        variant="contained"
        color={isFavorited ? "secondary" : "primary"}
        onClick={handleToggleFavorite}
        style={{ marginTop: "10px", marginLeft: "10px" }}
      >
        {isFavorited ? <BookmarkIcon /> : <BookmarkBorderIcon />}{" "}
      </Button>
      <Button
        variant="contained"
        color="primary"
        href={articleData.article.url}
        target="_blank"
        rel="noopener"
        style={{ marginTop: "10px" }}
      >
        Go to Source Article
      </Button>

      <Accordion
        expanded={expanded === "summary"}
        onChange={handleChange("summary")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">{articleData.article.summary}</Typography>
        </AccordionDetails>
      </Accordion>

      {articleData.terms && articleData.terms.length > 0 && (
        <Accordion
          expanded={expanded === "terms"}
          onChange={handleChange("terms")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Terms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={2}>
              {articleData.terms.map((term) => (
                <Grid2 size={{ xs: 6 }}>
                  <TermCard data={term} />
                </Grid2>
              ))}
            </Grid2>
          </AccordionDetails>
        </Accordion>
      )}

      {articleData.concepts && articleData.concepts.length > 0 && (
        <Accordion
          expanded={expanded === "concepts"}
          onChange={handleChange("concepts")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Concepts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={2}>
              {articleData.concepts.map((concept) => (
                <Grid2 size={{ xs: 6 }}>
                  <ConceptCard data={concept} />
                </Grid2>
              ))}
            </Grid2>
          </AccordionDetails>
        </Accordion>
      )}
    </Paper>
  );
};

export default ArticlePage;
