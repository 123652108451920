// src/components/QOTDComponent.jsx

import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Button,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const QOTDComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [question, setQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [loading, setLoading] = useState(true);
  const [answerResult, setAnswerResult] = useState(null);
  const [error, setError] = useState(null);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [streak, setStreak] = useState(0);

  const fetchQuestionStatus = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/question-of-the-day/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch question status");
      }
      const data = await response.json();
      setHasAnswered(data.has_answered);
      setStreak(data.streak);

      if (!data.has_answered) {
        // Fetch the question only if the user hasn't answered yet
        fetchQuestion(token);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching question status:", error);
      setError("Failed to fetch question status. Please try again.");
      setLoading(false);
    }
  };

  const fetchQuestion = async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/question-of-the-day`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch question of the day");
      }
      const data = await response.json();
      setQuestion(data);
    } catch (error) {
      console.error("Error fetching question of the day:", error);
      setError("Failed to fetch question of the day. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const submitAnswer = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/question-of-the-day/answer`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selected_answer: selectedAnswer }),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || "Failed to submit answer");
      }
      const data = await response.json();
      setAnswerResult(data);
      setHasAnswered(true);
      setStreak(data.streak);
    } catch (error) {
      console.error("Error submitting answer:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchQuestionStatus();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        {error}
      </Typography>
    );
  }

  if (hasAnswered) {
    return (
      <Paper style={{ padding: "20px", marginTop: "20px", marginBottom: "20px"  }}>
        <Typography variant="h5" align="center">Question of the Day</Typography>
        <Typography variant="subtitle1" align="center" style={{ marginTop: "10px" }}>
          You have already answered today's question.
        </Typography>
        <Typography variant="h6" align="center" style={{ marginTop: "10px" }}>
          Your streak: {streak}
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ marginTop: "10px" }}>
          Come back tomorrow for another question!
        </Typography>
      </Paper>
    );
  }

  if (!question) {
    return null;
  }

  return (
    <Paper style={{ padding: "20px", marginTop: "20px", marginBottom: "20px" }}>
      <Typography variant="h5">Question of the Day</Typography>
      <Typography variant="h6" style={{ marginTop: "10px" }}>
        {question.question}
      </Typography>
      <FormControl component="fieldset" style={{ marginTop: "10px" }}>
        <RadioGroup
          value={selectedAnswer}
          onChange={(e) => setSelectedAnswer(e.target.value)}
        >
          {question.answers.map((answer) => (
            <FormControlLabel
              key={answer.letter}
              value={answer.letter}
              control={<Radio />}
              label={`${answer.letter}. ${answer.answer_text}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!answerResult && (
        <Button
          variant="contained"
          color="primary"
          onClick={submitAnswer}
          disabled={!selectedAnswer}
          style={{ marginTop: "10px" }}
        >
          Submit Answer
        </Button>
      )}
      {answerResult && (
        <div style={{ marginTop: "10px" }}>
          <Typography
            variant="body1"
            style={{
              color: answerResult.is_correct ? "green" : "red",
            }}
          >
            {answerResult.is_correct
              ? "Correct!"
              : `Incorrect. The correct answer is ${answerResult.correct_answer}.`}
          </Typography>
          <Typography variant="body2" style={{ marginTop: "10px" }}>
            Explanation: {answerResult.explanation}
          </Typography>
          <Typography variant="body2" style={{ marginTop: "10px" }}>
            Your current streak: {answerResult.streak}
          </Typography>
        </div>
      )}
    </Paper>
  );
};

export default QOTDComponent;