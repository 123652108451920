import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid2,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useAuth0 } from "@auth0/auth0-react";
import ConceptCard from "../ConceptCard";
import TermCard from "../TermCard";

const ArticleCard = ({ url, article_id }) => {
  const [articleData, setArticleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [expanded, setExpanded] = useState(""); // Default to Summary open
  const [isFavorited, setIsFavorited] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleFavorite = async () => {
    try {
      const token = await getAccessTokenSilently();
      const item_id = articleData.article.id;
      const item_type = "Article";
      let response;
      if (isFavorited) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites/${item_type}/${item_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.ok) {
        setIsFavorited(!isFavorited);
      } else {
        const data = await response.json();
        console.error("Error updating favorite:", data.detail);
      }
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  useEffect(() => {
    const fetchArticleData = async () => {
      setLoading(true);
      try {
        console.log("STARTING GET ARTICLE");

        const token = await getAccessTokenSilently();
        console.log(url);
        // const urlParam = url | null;
        console.log(article_id);
        // const idParam = article_id | null;

        if (!url && !article_id) {
          console.error("No URL or ID provided");
          setLoading(false);
          return;
        }

        let queryParams = "";
        if (url) {
          queryParams = `url=${encodeURIComponent(url)}`;
        } else if (article_id) {
          queryParams = `id=${encodeURIComponent(article_id)}`;
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/article?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          console.log("ARTICLE RESPONSE OK");
          setArticleData(data);
          setIsFavorited(data.article.is_favorited);
        } else {
          console.error("Error fetching article:", data.detail);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleData();
  }, [getAccessTokenSilently, url, article_id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!articleData) {
    return <Typography variant="h6">Article not found</Typography>;
  }

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 size={12} style={{ marginLeft: "6px" }}>
            <Typography variant="h6">{articleData.article.title}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }} style={{ paddingLeft: "6px" }}>
            <Typography variant="body1">
              Published on: {formatDate(articleData.article.publication_date)}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }} style={{ paddingLeft: "6px" }}>
            <Typography variant="body1">
              Ingested on: {formatDate(articleData.article.timestamp)}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Accordion
              expanded={expanded === "summary"}
              onChange={handleChange("summary")}
              //   style={{ backgroundColor: "#1f232f" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  {articleData.article.summary}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid2>
          {articleData.terms && articleData.terms.length > 0 && (
            <Grid2 size={{ xs: 12 }}>
              <Accordion
                expanded={expanded === "terms"}
                onChange={handleChange("terms")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Terms</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 container spacing={2}>
                    {articleData.terms.map((term) => (
                      <Grid2 size={{ xs: 6 }}>
                        <TermCard data={term} />
                      </Grid2>
                    ))}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </Grid2>
          )}

          {articleData.concepts && articleData.concepts.length > 0 && (
            <Grid2 size={{ xs: 12 }}>
              <Accordion
                expanded={expanded === "concepts"}
                onChange={handleChange("concepts")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Concepts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 container spacing={2}>
                    {articleData.concepts.map((concept) => (
                      <Grid2 size={{ xs: 6 }}>
                        <ConceptCard data={concept} />
                      </Grid2>
                    ))}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </Grid2>
          )}
        </Grid2>
      </CardContent>
      <CardActions style={{ marginBottom: "12px", marginLeft: "6px" }}>
        <Button
          variant="contained"
          color={isFavorited ? "secondary" : "primary"}
          onClick={handleToggleFavorite}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          {isFavorited ? <BookmarkIcon /> : <BookmarkBorderIcon />}{" "}
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={articleData.article.url}
          target="_blank"
          rel="noopener"
          style={{ marginTop: "10px" }}
        >
          Go to Source Article
        </Button>
      </CardActions>
    </Card>
  );
};

export default ArticleCard;
