import { FlashcardArray } from "react-quizlet-flashcard";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, IconButton, Grid2, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";

const Flashcards = () => {
  const [terms, setTerms] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const controlRef = useRef({}); // {} should definitely be passed to useRef for it to work
  const currentCardFlipRef = useRef(); // For flipping cards
  const [currentCard, setCurrentCard] = useState(1);
  const theme = useTheme();

  // Memoized cards data
  const cards = useMemo(() => {
    const temp_cards = [];

    terms.forEach((term) => {
      temp_cards.push({
        id: term.item_id,
        frontHTML: (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%" // Ensures the Box takes the full height of the card
            textAlign="center"
            padding="16px"
          >
            <Typography
              variant="h3"
              color={`${theme.palette.mode === "dark" ? "#FFF" : "#000000"}`}
            >
              {term.item_data.term}
            </Typography>
          </Box>
        ),
        backHTML: (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            textAlign="center"
            padding="16px"
          >
            <Typography
              variant="subtitle1"
              color={`${theme.palette.mode === "dark" ? "#FFF" : "#000000"}`}
            >
              {term.item_data.definition}
            </Typography>
          </Box>
        ),
      });
    });

    return temp_cards;
  }, [terms, theme]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/favorites`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          const terms = [];
          data.forEach((item) => {
            if (item.item_type === "Term") {
              terms.push(item);
            }
          });
          setTerms([...terms]);
        } else {
          console.error("Error fetching favorites:", data.detail);
        }
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };

    fetchFavorites();
  }, [getAccessTokenSilently]);

  // Key event handling for navigation and flipping
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case "ArrowLeft":
          controlRef.current.prevCard(); // Move to previous card
          break;
        case "ArrowRight":
          controlRef.current.nextCard(); // Move to next card
          break;
        case "Space":
          event.preventDefault(); // Prevent space bar from scrolling
          currentCardFlipRef.current(); // Flip the card
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div style={{ maxWidth: "95%", margin: "auto" }}>
      <Typography variant="h4" style={{ paddingTop: "20px" }}>
        Flashcards
      </Typography>
      <Typography variant="subtitle1" style={{ paddingBottom: "20px" }}>
        Flashcards are pulled from your bookmarked terms
      </Typography>

      {/* FlashcardArray Component */}
      <div
        onClick={() => {
          currentCardFlipRef.current();
        }}
      >
        <FlashcardArray
          cards={cards}
          controls={false} // We will use custom controls
          forwardRef={controlRef} // Reference for previous/next navigation
          currentCardFlipRef={currentCardFlipRef} // Reference for flipping the card
          showCount={false}
          FlashcardArrayStyle={{ width: "100%" }}
          frontCardStyle={{ backgroundColor: theme.palette.background.paper }}
          backCardStyle={{ backgroundColor: theme.palette.background.paper }}
          cycle={true}
        />
      </div>

      {/* Navigation Controls with Grid2 */}
      <Grid2 container spacing={2} justifyContent="center" alignItems="center">
        <Grid2 item>
          <IconButton
            onClick={() => controlRef.current.prevCard()}
            aria-label="Previous Card"
          >
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid2>
        <Grid2 item>
          <IconButton
            onClick={() => controlRef.current.nextCard()}
            aria-label="Next Card"
          >
            <ArrowForwardIcon fontSize="large" />
          </IconButton>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default Flashcards;
