// BugReportPage.jsx
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid2
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const BugReportPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bug-report`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, description }),
      });

      if (response.ok) {
        setSuccessMessage('Bug report submitted successfully!');
        setTitle('');
        setDescription('');
        setErrorMessage('');
      } else {
        const data = await response.json();
        setErrorMessage(`Error: ${data.detail}`);
      }
    } catch (error) {
      console.error('Error submitting bug report:', error);
      setErrorMessage('An error occurred while submitting the bug report.');
    }
  };

  return (
    <Grid2 container spacing={3} style={{ maxWidth: '95%', margin: 'auto', marginTop: "16px" }}>
      <Grid2 size={12}>
        <Typography variant="h4" gutterBottom align='center' >
          Whoops! Sorry about that! Can you submit a Bug Report?
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <TextField
          label="Describe the issue (What Happened, What you were doing)"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Grid2>
      <Grid2 size={12}>
        <TextField
          label="Any other helpful details?"
          variant="outlined"
          fullWidth
          multiline
          rows={6}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Grid2>
      <Grid2 size={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid2>
      {successMessage && (
        <Grid2 size={12}>
          <Typography variant="body1" color="green">
            {successMessage}
          </Typography>
        </Grid2>
      )}
      {errorMessage && (
        <Grid2 size={12}>
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        </Grid2>
      )}
    </Grid2>
  );
};

export default BugReportPage;